.App {
  color: rgb(255, 252, 234);
  ;
}

.App-sky {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.App-logo {
  height: 68px;
  width: 62px;
  pointer-events: none;
  float: left;
  margin-right: 31px;
  margin-left: 21px;
  position: relative;
  top: 7px;
}

.App-header {
  margin-bottom: 24px;
}

.App-projects {
  padding: 24px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 23s alternate ease-in-out;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(-3deg);
  }
  to {
    transform: rotate(0deg);
  }
}
