.Headline {
  position: relative;
}

.Headline-image {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 0.7s;
}

.Headline-left>.Headline-image {
  left: 0;
}

.Headline-right>.Headline-image {
  right: 0;
}

.Headline-loaded .Headline-image.Headline-image-active {
  opacity: 1;
}

.Headline-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 2048px;
  min-width: 128px;
}

.Headline-shield {
  border-radius: 10px;
  height: 100%;
  background-color: #383261;
  border: #383261 solid 1px;
  position: absolute;
  top: 0;
}

.Headline-dark .Headline-shield {
  background-color: #151735;
  border-color: #151735;
}

.Headline-texture {
  border-radius: 10px;
  height: 100%;
  background-image: url(./perforated.png);
  background-repeat: repeat;
  background-color: rgb(217, 223, 165);
  position: absolute;
  top: 0;
  opacity: 0;
  border: rgb(217, 223, 165) solid 1px;
  transition: opacity 1s;
}

.Headline-dark .Headline-texture {
  opacity: 0.07;
}

.Headline-focused .Headline-texture {
  opacity: 0.27;
}

.Headline-content-container {
  position: relative;
  font-size: 23px;
  font-weight: 250;
  letter-spacing: 0.04em;
  line-height: 1.2em;
}

.Headline-content {
  padding: 17px;
}

.Headline-link {
  color: inherit;
  font-weight: 100;
  text-decoration-thickness: 1px;
}

.Headline-name {
  font-weight: 550;
  letter-spacing: 0.12rem;
}

.Headline-image.Headline-round {
  border-radius: 50%;
  border: rgba(56, 50, 97, 0.9) solid 3px;
  transform: scale(0.7);
  transform-origin: top center;
  transition: opacity 1.1s, border-radius 1.1s, transform 1.1s;
}

.Headline-focused .Headline-image.Headline-round {
  border-radius: 10%;
  transform: none;
  z-index: 1;
}

.Headline-image.Headline-drain {
  transform: scale(0.3) rotate(120deg);
  transition: opacity 0.5s, transform 0.5s;
}

.Headline-image.Headline-drain.Headline-image.Headline-image-active {
  transform: none;
}

.Headline-image.Headline-flip {
  transform: rotateX(180deg) rotateY(-180deg);
  transition: opacity 0.7s, transform 0.7s;
}

.Headline-image.Headline-flip.Headline-image.Headline-image-active {
  transform: none;
}

.Headline-image.Headline-shrink {
  transform: scale(0.76);
  transition: opacity 1.7s, transform 1.7s;
}

.Headline-image.Headline-shrink.Headline-image.Headline-image-active {
  transform: none;
}

.Headline-image.Headline-flipped {
  transform: scaleX(-1);
}

.Headline-link::before {
  content: "‣\00a0";
}