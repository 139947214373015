.Projects-project {
  padding: 17px 0;
}

.Projects-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 100;
  font-size: 140%;
  margin-bottom: 3px;
}

.Projects-description {
  margin: 3px 0;
  font-size: 93%;
  margin-right: 29px;
}

.Projects-metadata {
  font-size: 93%;
  font-weight: 350;
  letter-spacing: 1px;
}

.Projects-metadata-content::before {
  content: "⮀ ";
  font-size: 60%;
  position: relative;
  top: -3px;
  margin-right: 8px;
}

.Projects-link a {
  color: inherit;
  text-decoration: none;
}

.Projects-image img {
  border-radius: 7px;
  max-width: 40vw;
}
