.Article-body a {
  color: inherit;
  font-weight: lighter;
}

.Article-body a.anchor {
  text-decoration: none;
}

.Article-body code {
  color: rgba(217, 223, 165, 0.87);
  border: rgba(217, 223, 165, 0.15) solid 1px;
  background-color: rgba(56, 50, 97, 0.5);
  border-radius: 4px;
  padding: 0 3px;
  font-size: 80%;
}

.Article-body a::before {
  content: "‣\00a0";
}

.Article-header img {
  border-radius: 5px;
  max-height: 40vh;
}

.Article-header .Headline-name {
  font-size: 37px;
  line-height: 37px;
  letter-spacing: 0.2em;
  font-weight: 100;
  display: block;
  margin-bottom: 8px;
}

.Article-header .Headline-description {
  font-size: 19px;
  font-weight: 100;
}