.Header-menu {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: 120px;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
  z-index: 1;
}

.Header-menu.Header-opaque {
  background-color: rgba(56, 50, 97, 0.7);
  border-bottom: rgba(56, 50, 97, 0.9) solid 2px;
}

.Header-site-title {
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  font-weight: 200;
  letter-spacing: 5px;
  position: relative;
  bottom: 13px;
}

.Header-links {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  font-size: 27px;
  font-weight: 300;
  letter-spacing: 1px;
  align-items: baseline;
  justify-content: space-evenly;
  text-transform: uppercase;
  min-width: 300px;
}

.Header a {
  color: inherit;
  text-decoration: none;
}

.Header-links a {
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}
