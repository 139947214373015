@keyframes darkeningAnimation {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 10%;
  }
}

@keyframes brightenAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Stars-sky {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: linear-gradient(#383261, #151735);
  animation: darkeningAnimation 60s ease-out 0.5s forwards;
}

.Stars-star {
  position: absolute;
  opacity: 0;
  animation: brightenAnimation 1s ease-in forwards;
  z-index: -1;
}