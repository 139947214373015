.Contact {
  padding: 33px;
}

.Contact-heading {
  margin-top: 147px;
  letter-spacing: 3px;
}

.Contact-links {
  display: table;
  margin-left: auto;
  margin-right: auto;
  font-size: 5vw;
}

.Contact-link {
  margin: 6vh;
}

.Contact-links a {
  color: inherit;
  text-decoration: none;
  font-weight: 100;
}

.Contact-links a::before {
  content: "‣\00a0";
}