.Articles {
  padding: 33px;
}

.Articles-heading {
  margin-top: 147px;
  letter-spacing: 3px;
}

.Articles-heading a {
  color: inherit;
  text-decoration: none;
}

.Articles-bullet {
  transform: scaleX(-1);
  display: inline-block;
}